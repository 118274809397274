<template>
    <div class="wordsEvaluation flex-column-between">
        <entrance :goback='goback'></entrance>
        <div class="iput flex-align-center">视频同步投影</div>
        <div class="projections flex-align-center">
            <div class="projectionText flex-column">
                <div class="hint">提示：</div>
                <div class="guidance flex-align-center">
                    <span>教师登录“首师优字教师端小程序”后，打开书写视频，点击</span>
                    <img src="../../assets/assistant/camera.png" />
                    <span>进行投影</span>
                </div>
                <div class="qrcode flex-column-center">
                    <div class="qrcodeDom flex-align">
                        <div class="qrcodeImg flex-align-center">
                            <img src="@/assets/assistant/qrcode.jpg" />
                        </div>
                        <div class="qrcodetext flex-column">
                            <span>微信扫描二维码，</span>
                            <span>打开小程序</span>
                        </div>
                    </div>
                    <span class="tips">（注意：小程序和电脑端要使用同一账号）</span>
                </div>
            </div>
        </div>
        <!-- 视频同步投影弹窗 -->
        <el-dialog custom-class="videoDialog" :visible.sync="dialogVisible" :close-on-click-modal="false"
            :show-close="false">
            <div class="polyvPlayer" id='player'></div>
        </el-dialog>
        <img class="close" @click="videoClose" v-if="dialogVisible" src="@/assets/copybook/close2.png" alt="">
    </div>
</template>

<script>
    import entrance from "../../Common_components/head/entrance";
    export default {
        data() {
            return {
                //WebSocket
                websock: null, //建立的连接
                lockReconnect: false, //是否真正建立连接
                timeout: 5 * 1000, //20秒一次心跳
                timeoutObj: null, //心跳心跳倒计时
                serverTimeoutObj: null, //心跳倒计时
                timeoutnum: null, //断开 重连倒计时
                date: "",
                closeJunction: '{"msg":"心跳操作","code":209}',
                reportData: "",
                throttle: 0,
                resource: false,
                delStatus: false,
                workBtntype: false,
                appraisalIindex: 0,
                loading: false,
                prevType: false,
                prevType: false,
                nextType: false,
                popupIndex: 0,
                commenVisible: false,
                revealType: false,
                appraisalType: false,
                indicatorValue: [],
                dialogVisible: false,
                exitDialogVisible: false,
                player: '',
                vodPlayerJs: 'https://player.polyv.net/script/player.js',
                options: [],
                classDefault: '',
                inputVal: '', //作品集名称
                showClassName: true,
                saveText: '保存并退出',
                vid: null,
                currentTime: null,
                playsafe: '', //解密凭证
                dialogTitle: '退出投影',
                step: 1
            };
        },
        components: {
            entrance,
        },
        //方法
        methods: {
            goback() {
                // this.websocketclose();
                this.websock.send(this.closeJunction);
                this.websock.close()
                this.$router.go(-1);
            },
            //初始化 websocket
            initWebSocket() {
                //建立连接
                // let wsuri =`apitest.lezhireading.com/api/learn/socket/projection/video/${sessionStorage.getItem("teacherId")}`
                let wsuri = `yunapi.cnuzi.com/api/learn/socket/projection/video/${sessionStorage.getItem("teacherId")}`
                //建立连接
                this.websock = new WebSocket("wss://" + wsuri);
                //连接成功
                this.websock.onopen = this.websocketonopen;
                //连接错误
                this.websock.onerror = this.websocketonerror;
                //接收信息
                this.websock.onmessage = this.websocketonmessage;
                //连接关闭
                this.websock.onclose = this.websocketclose;
            },
            //重新连接 websocket
            reconnect() {
                var that = this;
                if (that.lockReconnect) {
                    return;
                }
                that.lockReconnect = true;
                //没连接上会一直重连，设置延迟避免请求过多
                that.timeoutnum && clearTimeout(that.timeoutnum);
                that.timeoutnum = setTimeout(function () {
                    //新连接
                    that.initWebSocket();
                    that.lockReconnect = false;
                }, 5000);
            },
            //重置心跳
            reset() {
                var that = this;
                //清除时间
                clearTimeout(that.timeoutObj);
                clearTimeout(that.serverTimeoutObj);
                //重启心跳
                that.start();
            },
            //开启心跳
            start() {
                var self = this;
                self.timeoutObj && clearTimeout(self.timeoutObj);
                self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
                self.timeoutObj = setInterval(function () {
                    console.log('开启心跳')
                    //这里发送一个心跳，后端收到后，返回一个心跳消息
                    // console.log('self.websock.readyState', self.websock.readyState)
                    if (self.websock.readyState == 1) {
                        // console.log('self.date', self.date)
                        // self.websock.send(self.date);
                        // self.websocketsend();
                        self.websock.send(JSON.stringify({
                            name: "heartbeat",
                            code: 201
                        }));
                    } else {
                        //否则重连
                        self.reconnect();
                    }
                    // self.serverTimeoutObj = setTimeout(function () {
                    //   //超时关闭
                    //   self.websock.close();
                    // }, self.timeout);
                }, self.timeout);
            },
            //websocket 连接成功回调
            websocketonopen() {
                // console.log('websocket连接成功')
                //连接成功事件
                this.websocketsend();
                //提示成功
                //开启心跳
                this.start();
            },
            //websocket 连接失败回调
            websocketonerror(e) {
                console.log('websocket连接失败', e)
                //连接失败事件
                //错误
                //重连
                // this.reconnect();
            },
            //websocket 连接关闭回调
            websocketclose(e) {
                console.log('websocket连接关闭');
                // this.websock.close()
                //连接关闭事件
                //提示关闭
                //重连
                // this.reconnect();
            },
            //websocket 接收服务器推送的信息
            websocketonmessage(event) {
                let data = JSON.parse(event.data);
                console.log('//接收服务器推送的信息', data)
                if (this.throttle == 0 && data.data.code == 1002) {
                    // this.getWordImg();
                    this.throttle = 1;
                }
                if (data.data.code == 1001) {
                    // this.getWordImg();
                }
                if (data.code == 1003) {
                    this.destroyed();
                    this.vid = JSON.parse(data.data.code).code;
                    this.currentTime = JSON.parse(data.data.code).currentTime
                    this.dialogVisible = true;
                    this.loadPlayer();
                }
                // data.code = 200;
                //收到服务器信息，心跳重置
                this.reset();
            },
            //websocket 发送信息
            websocketsend() {
                // console.log('websocket发送信息')
                let msg = {
                    code: 2001,
                    data: JSON.stringify(this.$route.query),
                };
                // this.date = JSON.stringify(msg);
                //向服务器发送信息
                // console.log('//向服务器发送信息', JSON.stringify(msg))
                this.websock.send(JSON.stringify(msg));
            },
            //  ------ 1.9需求新增 ------
            videoClose() {
                this.dialogVisible = false;
                this.destroyed()
            },
            // loadPlayerScript(callback) {
            //     if (!window.polyvPlayer) {
            //         const myScript = document.createElement('script');
            //         myScript.setAttribute('src', this.vodPlayerJs);
            //         myScript.onload = callback;
            //         document.body.appendChild(myScript);
            //     } else {
            //         callback();
            //     }
            // },

            loadPlayer() {
                this.$nextTick(() => {
                    const polyvPlayer = window.polyvPlayer;
                    this.player = polyvPlayer({
                        wrap: '#player',
                        playsafe: this.playsafe,
                        // width: 800,
                        // height: 533,
                        vid: this.vid,
                        watchStartTime: this.currentTime,
                        autoplay: true
                    });
                })

            },
            destroyed() {
                if (this.player) {
                    this.player.destroy();
                }
            },
        },
        mounted() {
            // this.loadPlayerScript(this.loadPlayer);
            this.initWebSocket();
        },
    };
</script>
<style lang='less' scoped>
    @import "./less/syncProjection.less";

    .carouselMian {
        height: 140px;
        width: 810px;

        /deep/.slider-wrapper {
            margin: 0 2px;
        }

        /deep/.slider-item {
            width: 112.4px !important;
            height: 135px !important;
            margin-right: 26px;
        }

        /deep/.slider-pagination {
            display: none;
            // bottom: 0px;
        }

        /deep/.swiper-container-horizontal>*>.slider-pagination-bullet {
            width: 20px !important;
            height: 8px !important;
            border-radius: 14px;
            background-color: #c2c2c2;
        }

        /deep/.swiper-container-horizontal .slider-pagination-bullet-active {
            background-color: #89dcc9;
        }
    }
</style>